// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { enhanceContent } from "../lib/post"

// helpers
import { up, addResponsivity } from "../lib/styles"
import { extractNodesFromDataApplyLangs } from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"
import iconCaretPath from "../data/images/icon-menu-caret.svg"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  ChevronIcon,
  DashedLine,
} from "../components"
import Accordion from "../components/Accordion"

// assets

// custom styled components

const AccordionTitleWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 16px 0;
  `}
`

const DropDownWrapper = styled.div`
  ${({ isOpen }) => css`
    position: relative;
    padding: 20px 16px 0;
    /* display: none;
    ${up("menu")} {
      display: block;
    } */
    /* ${isOpen &&
    css`
      &:after {
        content: "";
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 10;
      }
    `} */
  `}
`

const CaretIcon = styled.img`
  ${({ up }) => css`
    transform: rotate(${up ? 180 : 0}deg);
  `}
`

const DropDown = styled.div`
  ${({ theme: { colors }, isOpen }) => css`
    flex-direction: column;
    /* align-items: center; */
    position: absolute;
    /* top: 28px; */
    top: 80%;
    left: 0;
    background: white;
    border-radius: 4px;
    margin: 16px 0px;
    box-shadow: 0 2px 8px rgba(64, 82, 171, 0.16);
    padding: 16px 20px;
    display: ${isOpen ? "flex" : "none"};
    z-index: 100;
  `}
`

const DropDownOverlay = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 10;
  opacity: 0.2;
`

const DropDownItem = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

const CategoryLink = styled(Link).attrs({
  upperCase: true,
  withFancyUnderline: true,
  fontSize: "16px",
  lineHeight: "22px",
  // mobileFontSize: '14px',
  // mobileLineHeight: '18px',
  green: true,
  withHover: true,
})`
  ${({ theme: { colors, fonts } }) => css`
    /* max-width: 250px; */
  `}
`

const AnswerContent = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    font-size: 16px;
    line-height: 24px;
    font: ${fonts.muli};

    h2 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin: 16px 0 8px 0;
      color: ${colors.blue};
      font: ${fonts.signika};
    }

    a {
      color: ${colors.green};

      &:hover {
        color: ${colors.darkGreen};
        text-decoration: none;
      }
    }
  `}
`

class FAQ extends React.Component {
  state = {
    open: {},
    selectedTopic: "",
    isCategoryDropdownOpen: false,
  }

  toggleQuestion = _id => {
    this.setState(state => ({
      open: {
        ...state.open,
        [_id]: !state.open[_id],
      },
    }))
  }

  toggleCategoryDropdown = () => {
    this.setState(state => ({
      isCategoryDropdownOpen: !state.isCategoryDropdownOpen,
    }))
  }

  onCategoryClick = selectedTopic => {
    if (this.state.selectedTopic === selectedTopic)
      return this.setState({ selectedTopic: "" })
    this.setState({ selectedTopic })
  }

  closeCategoryDropdown = () => {
    this.setState(state => ({ isCategoryDropdownOpen: false }))
  }

  onMobileCategoryClink = selectedTopic => {
    this.setState({ selectedTopic, isCategoryDropdownOpen: false })
  }

  render() {
    const {
      location: { pathname },
      intl: { language, t },
    } = this.props

    const { open, selectedTopic, isCategoryDropdownOpen } = this.state
    const { qas, qaTopics } = extractNodesFromDataApplyLangs(
      this.props.data,
      language
    )
    const nonEmptyTopics = qaTopics.filter(({ _id }) =>
      qas.some(({ topic }) => topic?._id === _id)
    )
    const middleIndex = Math.ceil(nonEmptyTopics.length / 2)
    const firsColumnTopics = nonEmptyTopics.slice(0, middleIndex)
    const secondColumnTopics = nonEmptyTopics.slice(middleIndex)

    let selectedQuestions = qas
    if (selectedTopic) {
      selectedQuestions = qas.filter(
        ({ topic }) => topic?._id === selectedTopic
      )
    }

    const renderMenuRow = ({ _id, name }) => (
      <Row key={_id} margin="0 0 12px 0">
        {/* •<Gap gap="24px"/> */}
        {/* <Col justifyContent="center">
          <span style={{ color: '#00b3a6' }}>•</span>
        </Col>
        <Gap gap="12px" /> */}
        <Col>
          <CategoryLink
            asSpan
            isActive={_id === selectedTopic}
            onClick={() => this.onCategoryClick(_id)}
          >
            {name}
          </CategoryLink>
        </Col>
      </Row>
    )

    const selectedTopicName = nonEmptyTopics.find(
      ({ _id }) => selectedTopic === _id
    )?.name

    return (
      <>
        <SEO
          title={t("faq - meta title")}
          description={t("faq - meta desc")}
          pathname={pathname}
        />

        <Gap.Top />

        <Col maxWidth="820px" width="100%" alignSelf="center">
          <Row>
            <Gap gap="16px" bp="desktop" />
            <Title.PageTitle>
              {/* TODO: vetsi */}
              <T>Časté dotazy</T>
            </Title.PageTitle>
          </Row>

          <RH hideAfter="mobile">
            <DropDownWrapper isOpen={isCategoryDropdownOpen}>
              {isCategoryDropdownOpen && (
                <DropDownOverlay onClick={this.closeCategoryDropdown} />
              )}
              <Link.NavLink
                asSpan
                onClick={this.toggleCategoryDropdown}
                // isPinned={isPinned}
                // isOpen={isOpen}
              >
                <Row>
                  <div style={{ fontSize: "18px" }}>
                    {selectedTopicName || <T>Všechny otázky</T>}
                  </div>
                  <Gap gap="8px" />
                  <CaretIcon up={isCategoryDropdownOpen} src={iconCaretPath} />
                </Row>
              </Link.NavLink>
              <DropDown isOpen={isCategoryDropdownOpen}>
                {nonEmptyTopics.map(({ _id, name }) => (
                  <DropDownItem>
                    <CategoryLink
                      asSpan
                      isActive={_id === selectedTopic}
                      onClick={() => this.onMobileCategoryClink(_id)}
                    >
                      {name}
                    </CategoryLink>
                  </DropDownItem>
                ))}
                {selectedTopicName && (
                  <DropDownItem>
                    <CategoryLink
                      asSpan
                      onClick={() => this.onMobileCategoryClink(null)}
                    >
                      <T>Všechny otázky</T>
                    </CategoryLink>
                  </DropDownItem>
                )}
              </DropDown>
            </DropDownWrapper>
          </RH>
          <Gap gap="54px" mobileGap="24px" />

          <RH showAfter="mobile">
            <Row>
              <Gap gap="16px" />
              <Row responsive>
                <Col>{firsColumnTopics.map(renderMenuRow)}</Col>
                <Gap gap="40px" mobileGap="0" bp="mobile" />
                <Col>{secondColumnTopics.map(renderMenuRow)}</Col>
                <Gap gap="16px" />
              </Row>
            </Row>
            <Gap gap="36px" mobileGap="24px" />
          </RH>

          {/* first dashed line */}
          <DashedLine />
          {selectedQuestions.map(q => (
            <Col key={q._id}>
              <Row>
                {/* horizontal gap */}
                <Gap gap="16px" />
                <Col width="100%">
                  <AccordionTitleWrapper
                    onClick={() => this.toggleQuestion(q._id)}
                  >
                    <Row justifyContent="space-between" alignItems="center">
                      <Title fontSize="24px" lineHeight="30px" as="h2">
                        {q.question}
                      </Title>
                      <ChevronIcon.FAQ isOpen={open[q._id]} />
                    </Row>
                  </AccordionTitleWrapper>
                  <Accordion isOpen={open[q._id]}>
                    <AnswerContent>
                      {enhanceContent({ content: q.answer })}
                    </AnswerContent>
                    <Gap gap="40px" mobileGap="16px" />
                  </Accordion>
                </Col>
                <Gap gap="16px" />
              </Row>

              <DashedLine />
            </Col>
          ))}
          <Gap gap="80px" mobileGap="40px" />
          <ContactFormSection />
        </Col>
      </>
    )
  }
}

export default injectIntl(FAQ)

export const pageQuery = graphql`
  query ($language: String!) {
    qaTopics: allQatopics(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          ...QATopicFragment
        }
      }
    }
    qas: allQas(
      sort: { fields: sortOrder, order: ASC }
      filter: { isPublished: { eq: true }, lang: { eq: $language } }
    ) {
      edges {
        node {
          ...QAFragment
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
